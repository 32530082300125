/* * {transition: all 10ms ;} */
body,
main,
#root {
    /* background-color: #2c3e50; */
    background-color: #ecf0f3;
    color: #020204;
    overflow: hidden;
    /* text-size-adjust: 100%; */
    overflow: hidden;
    height: 100vh;
}

@media print {

    body,
    main,
    #root {
        overflow: unset;
        width: 100vw;
    }

    @page {
        margin: 0;
    }
}

.this-button {
    font-family: "'BoutrosMBCDinkum Medium'";
    border-radius: 0.3rem;
    padding: 8px 16px;
    font-size: 13px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    letter-spacing: 1px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    color: rgb(47, 59, 75);
}


.this-button.active {
    background-color: rgb(248 113 113);
    color: #ffffff;
}

.this-button .confriem {
    background-color: rgb(248 113 113);
    color: #ffffff;
}

.this-button .cancel {
    background-color: rgb(75 85 99);
    color: #ffffff;
}

.this-button-icon {
    border-radius: 0.3rem;
    padding-top: 4px;
    padding-inline: 6px;
    font-size: 13px;
    cursor: pointer;
    letter-spacing: 1px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    color: rgb(47, 59, 75);
}

/*  */


.bills-add-buttons {
    /* margin-left: 20px; padding-right: 100px; */
    flex-grow: 1;
    height: 45px;
    padding-top: 4px;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-inline: 4px;

}

.bill-button {
    font-family: "'BoutrosMBCDinkum Medium'";
    color: #ffffff;
    background-color: #c1c9d6;
    border-radius: 0.1rem;
    font-size: 16px;
    cursor: pointer;
    font-weight: bold;
    padding: 2px 6px;
    padding-top: 6px;
    min-width: 16px;
    text-align: center;
    /* margin-bottom: -10px; */
    transition: all 100px ease-in;

}

.border-gray {
    border: none;
}

/* .border-gray{border: solid 1px #bbc7d8 ;} */
/* .border-gray{border: solid 1px #95a9c7 ;} */

.selected-bill {
    font-family: "'BoutrosMBCDinkum Medium'";
    color: #ffffff;
    background-color: rgb(248 113 113);
    border-radius: 0.1rem;

    font-size: 20px;
    padding: 7px 4px;
    margin-bottom: -10px;
    min-width: 26px;
    text-align: center;
    font-weight: bold;
}

.add-bill-button {
    /*
    top: 22px; */
    padding: 2px 10px;
    font-size: 22px;
    margin-top: -2px;
    color: #ffffff;
    border-radius: 0.2rem;
    background-color: rgb(248 113 113);
}

.popup-buttons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    position: absolute;
    background-color: #fff;
    padding: 10px 15px;
    left: 0;
    right: 0;
    max-width: 200px;
    margin-inline: auto;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    border-radius: 0.2rem;
    /* left: 0;
    right: 0; */
}

.popup-buttons :nth-child(1) {
    grid-column: 1/-1;
}


.link-btn {
    font-family: "'BoutrosMBCDinkum Medium'";
    color: #2c3e50;
    background-color: #ffffff;
    border-radius: 0.1rem;
    font-size: 16px;
    cursor: pointer;
    font-weight: bold;
    padding: 2px 6px;
    padding-top: 6px;
    min-width: 16px;
    text-align: center;
    /* margin-bottom: -10px; */
    transition: all 100px ease-in;
}

.this-button-2 {
    font-family: "'BoutrosMBCDinkum Medium'";
    font-weight: bold;
    border-radius: 0.3rem;
    cursor: pointer;
    /* border: solid 2px #f87171; */
    color:#f87171;
    padding: 4px 8px;
    font-size: 13px;
    cursor: pointer;
    letter-spacing: 1px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}
.border-top{ 
    border-top: solid 1px #bbc7d8;
    padding-top:10px;
}

.close-icon {
    font-size: 20px;
    cursor: pointer;
    border: solid 2px #f87171;
    border-radius: 10px;
    padding-inline: 9px;
}

.paid-input {
    font-family: "'BoutrosMBCDinkum Medium'";
    font-weight: bold;
    outline: none;
    border: none;
    color: #2d303e;
    border-radius: 0.3rem;
    width: 70px;
    padding: 2px 5px;
    padding-left: 24px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
}

.paid-input:focus {
    box-shadow: 0px 0px 6px rgba(32, 211, 238, 0.5);
}


.details-grid {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 5px;

    /* background-color: #0001; */
}

/* .details-grid :nth-child(1){
    background-color: #00f1;
}
.details-grid :nth-child(2){
    background-color: #7a52;
}
.details-grid :nth-child(3){
    background-color: #0552;
}
.details-grid :nth-child(4){
    background-color: #2512;
}
.details-grid :nth-child(5){
    background-color: #f512;
}
.details-grid :nth-child(6){
    background-color: #6f12;
}
.details-grid :nth-child(7){
    background-color: #0a0;
} */

.morabaa-icon {}

.morabaa-icon .bg {
    fill: #1f2937;
}

.morabaa-icon .curve {
    fill: #f87171;
}

.fill-white {
    fill: #fff;
}

.error {
    animation: shakeFrame 500ms linear;
    box-shadow: 0 0 8px #f8717155;
}
.shake {
    animation: shakeFrame 500ms linear !important;
    box-shadow: 0 0 8px #f8717155;
}
.shake::placeholder {
    color: #f87171 !important; 
    font-weight: bold;
}

.loading {
    position: relative;
    padding-left: 40px;
    transition: all 0.2s;
}

.loading #loader-target {
    padding: 6px;
    border-radius: 50px;
    border: 5px #22c55e55 solid;
    border-left-color: #22c55e;
    animation: loadingFrame 500ms infinite linear;
    position: absolute;
    left: 8px;
    margin-top: -3px;
}

@keyframes loadingFrame {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Shack  */
@keyframes shakeFrame {
    0%,20%,40%,60%,80% {transform: translateX(0);}
    10%,30%,50%,70%,90% {transform: translateX(-6px);}
}

/* Statues  */

[localName=selected]{box-shadow: 0px 0px 4px rgba(32, 211, 238,0.6) !important;}
[localName=warn]{box-shadow: 0px 0px 4px rgb(248, 148, 6,0.6) !important;}
[localName=error]{box-shadow: 0px 0px 4px rgba(248 ,113 ,113 ,0.6) !important;}
[localName=ok]{box-shadow: 0px 0px 4px rgba(190 ,242 ,100 ,0.6) !important;}

[localName=selected] .state-target{
    /* background-color: rgba(32, 211, 238,0.4); */
    box-shadow: 0px 0px 6px rgba(32, 211, 238,0.5);
    color:rgb(21 94 117)
}
    
[localName=warn] .state-target{
    /* background-color: rgba(253 ,224 ,71 ,0.4); */
    box-shadow: 0px 0px 6px  rgb(248, 148, 6,0.5);
    color: rgb(248, 148, 6,1)
}
    
[localName=error] .state-target{
    /* background-color: rgba(248 ,113 ,113 ,0.4); */
    box-shadow: 0px 0px 6px rgba(248 ,113 ,113 ,0.5);
    padding:0;
    color:rgb(248 113 113)
}
    
[localName=ok] .state-target{
    /* background-color: rgba(190 ,242 ,100 ,0.4); */
    box-shadow: 0px 0px 6px rgba(190 ,242 ,100 ,0.5);
    color:rgba(190 ,242 ,100 ,0.7)
}
[localName=none] .state-target{height: 0;font-size: 0; }

.not-allowed-quantity{
    color: #f87171;
    font-weight: bold;
    font-size: 12px;
    padding: 2px 4px;
    border-radius: 0.3rem;
    background-color: #f8717155;
    box-shadow: 0px 0px 4px rgba(248 ,113 ,113 ,0.6);
    width: 20px;
    text-align: center;
}

/* Packges */
.packages-container{
    display:inline-flex;
    gap: 10px;
}

.package{
    padding: 10px;
    margin-block: 10px;
    border-radius: 0.3rem;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
    background-color: #fff;
    flex-grow: 1;
}

/* .package:hover{
    box-shadow: 0px 0px 6px rgba(32, 211, 238, 0.5);
} */

.package.selected {
    box-shadow: 0px 0px 6px rgba(32, 211, 238, 0.5);
}