.info-container{
    position: fixed;
    right: 10px;
    top: 30px;
    padding: 10px 40px;
    gap: 10px;
    z-index: 3333;
    display: flex;
    flex-direction: column;
    border-radius: 0.3rem;
    box-shadow: 0px 0px 8px #2f3b4b33 !important;
    max-width: 400px;
    min-width: 200px;
}


.info-container .x{
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
    font-size: 1rem;
    color: #fff;
    border-radius: 0.3rem;
    opacity: 0.5;
    padding-inline: 0.4rem;
    font-weight: bold;
}

.info-fade-in{
    animation: fade-in 400ms ease-out !important;
}

.info-fade-out{
    opacity: 0;
    animation: fade-out 500ms ease-in !important;
}


@keyframes fade-in {
    0% {
        scale: 0;
        opacity: 0;
        right: -400px;
        padding: 0;
    }
    70% {
        scale: 1.1;
        right: 50px;
        padding: 10px 40px;
    }
    100% {
        scale: 1;
        opacity: 1;
        right: 10px;
    }
}

@keyframes fade-out { 0% {opacity: 1; } 70% {opacity: 0.1;} 100% {opacity: 0;right: -400px;} }

@keyframes shakeFrame {
    0%,20%,40%,60%,80% {transform: translateX(0);}
    10%,30%,50%,70%,90% {transform: translateX(-6px);}
}