.ltr {direction: ltr;}
.addon-main {
    font-weight: bold;
    font-family: "'BoutrosMBCDinkum Medium'";
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 13px;
    /* flex-grow: 1; */
}

.addon-container{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 5px;
}

.addon-input-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    gap: 5px;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
    padding-inline: 10px;
    flex-grow: 1;
}

.addon-input {
    padding:8px;
    outline: none;
    border: none;
    color: #2d303e;
    flex-grow: 1;
    font-weight: bold;
    font-family: "'BoutrosMBCDinkum Medium'";
}

.addon-input-container:focus-within{box-shadow: 0px 0px 6px rgba(32, 211, 238,0.5);}
.addon-input::placeholder{font-weight: 500; font-family: "'BoutrosMBCDinkum Medium'";font-size: 13px;opacity:0.8;}

.addon-button{
  background-color: #fff;
  box-shadow:  0 0  8px rgba(0,0,0,0.16);
  padding: 8px 10px;
  border-radius: 4px;
  cursor: pointer;
  align-self: flex-start;
}

/* cancel-button  */
.addon-cancel-button{
    cursor: pointer;
}
#string .addon-cancel-button{
    padding: 6px 0 6px 6px;
    border-left: solid 1px #c0c0c0;
}
#string .ltr .addon-cancel-button{
    padding: 6px 6px 6px 0 ;
    border-right: solid 1px #c0c0c0;
    border-left: unset;
}
/* cancel-button  */

.addon-select {
    outline: none;
    border: none;
    font-family: "'BoutrosMBCDinkum Medium'";
    font-weight: bold;
}

.addon-select option{
    font-family: "'BoutrosMBCDinkum Medium'";
    font-weight: bold;
    outline: none;
    border: none;
    background-color: #fff;
}

.addon-save-button{
    padding: 6px 0 6px 6px;
    cursor: pointer;
    font-family: "'BoutrosMBCDinkum Medium'";
    font-weight: bold;
}

#stringOptions {
    background-color: #fff;
} 
#stringOptions .addon-input{
    border-left: solid 1px #c0c0c0;
    border-right: solid 1px #c0c0c0;
}

.addons-fade-in {
    animation: fade-in-height 300ms ease-in;
} 

.addons-fade-out {
    animation: fade-out-height 210ms ease-out;
    max-height:0;
    overflow: hidden;
} 

@keyframes fade-in-height {
    0% {
        max-height:0;
        opacity: 0;
    }
    100% {
        max-height:250px;
        opacity: 1;
    }
}

@keyframes fade-out-height {
    0% {
        max-height:150px;
        opacity: 1;
    }
    100% {
        max-height:0;
        opacity: 0;
    }
}