    :root {
    --text :#2c3e50;
    --primary :#3498db;
    --secondary :#9b59b6;
    --success :#2ecc71;
    --info :#3498db;
    --warning :#f1c40f;
    --danger :#e74c3c;
    --light :#ecf0f1;
    --dark :#2c3e50;
    --body :#fff;
    --border :#bdc3c7;
    --background :#ecf0f1;
    --background-secondary :#bdc3c7;
    --background-primary :#3498db;
    --background-success :#2ecc71;
    --background-info :#3498db;
    --background-warning :#f1c40f;
    --background-danger :#e74c3c;
    --background-light :#ecf0f1;
    --background-dark :#2c3e50;
    --text-primary :#2c3e50;
    --text-secondary :#9b59b6;
    --text-success :#2ecc71;
    --text-info :#3498db;
    --text-warning :#f1c40f;
    --text-danger :#e74c3c;
    --text-light :#ecf0f1;
    --text-dark :#2c3e50;
    --border-primary :#3498db;
    --border-secondary :#9b59b6;
    --border-success :#2ecc71;
    --border-info :#3498db;
    --border-warning :#f1c40f;
    --border-danger :#e74c3c;
    --border-light :#ecf0f1;
    --border-dark :#2c3e50;
    --background-primary-hover :#2c3e50;
    --background-secondary-hover :#9b59b6;
    --background-success-hover :#2ecc71;
    --background-info-hover :#3498db;
    --background-warning-hover :#f1c40f;
    --background-danger-hover :#e74c3c;
    --background-light-hover :#ecf0f1;
    --background-dark-hover :#2c3e50;
    --text-primary-hover :#2c3e50;
    --text-secondary-hover :#9b59b6;
    --text-success-hover :#2ecc71;
    --text-info-hover :#3498db;
    --text-warning-hover :#f1c40f;
    --text-danger-hover :#e74c3c;
    --text-light-hover :#ecf0f1;
    --text-dark-hover :#2c3e50;
    --border-primary-hover :#3498db;
        
    --border-secondary-hover :#9b59b6;
    --border-success-hover :#2ecc71;
    --border-info-hover :#3498db;
    --border-warning-hover :#f1c40f;
    --border-danger-hover :#e74c3c;

        
    /* --table :#fff;
    --even: #b3d9d2;
    --header: #76bfb1;
    --header-text: #fff;
    --line:#014b3d38;
    --rounded: 15px; */
     } 


     .bg-primary  {background-color: var(--primary);}
     .text-primary{color: var(--primary);}

    /* *{ transition: all 10s ease-in-out;} */
     .font-bold {
         font-family: "'BoutrosMBCDinkum Medium'";
         font-weight: bold;
     }
    /* bg-gray-50 un-selectble  */
    .transition-0{ transition: all 0s ease-in-out;}
    .transition-1{ transition: all 0.1s ease-in-out;}
    .transition-2{ transition: all 0.2s ease-in-out;}
    .transition-3{ transition: all 0.3s ease-in-out;}
    .transition-4{ transition: all 0.4s ease-in-out;}
    /* .owl-none{} */
    
    .owl-item-details{
        display: grid;
        /* flex-wrap: wrap; */
        /* grid-template-columns: repeat(4, 0.1fr,0.4fr,0.2fr, 0.1fr); */
        grid-template-columns: 1fr 0.1fr 0.1fr 0.1fr;
        /* flex-direction: row; */
        align-items: center;
        /* justify-content: left; */
        font-family: "'BoutrosMBCDinkum Medium'";
        font-weight: bold;
        /* box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.8); */
        /* border: dashed 1px #ccc ; */
        padding: 8px;
        border-radius: 5px;
        background-color: #f5f5f5;
    }
    /* .owl-item-details :nth-child(1){
     background-color: #2ecc71;        
    }
    .owl-item-details :nth-child(2){
        background-color: #3498db;        
    }
    .owl-item-details :nth-child(3){
     background-color: #2c3e50;        
    }
    .owl-item-details :nth-child(4){
     background-color: #f1c40f;        
    }
    .owl-item-details :nth-child(5){
     background-color: #9b59b6;        
    } */


    #plus {
        color: #40acdc;
        background-color: #fff;
        border-radius: 0.2rem;
        font-size: 21px;
        padding:2px 12px;
        cursor: pointer;
        /* box-shadow:  0px 0px 5px rgba(0, 0, 0, 0.1); */
        border:solid 1px rgba(32, 211, 238,0.3);

        /* box-shadow:0px 0px 5px rgba(32, 211, 238,0.3); */
    }

    #minus {
        color:rgb(248 113 113);
        background-color: #fff;
        border-radius: 0.2rem;
        font-size: 21px;
        padding:2px 14px;
        cursor: pointer;
        /* box-shadow:  0px 0px 5px rgba(0, 0, 0, 0.1); */
        border:solid 1px rgba(248 ,113 ,113 ,0.3);

        /* box-shadow:0px 0px 5px rgba(248 ,113 ,113 ,0.3); */

    }

    #delete{
        cursor: pointer;
        height: 25px;
    }
    #delete:hover{
        /* rotate and go up */
        transform: rotate(-13deg) translateY(-3px);
        transition: all 0.1s ease-in-out;
     }

    #itemQuantity {
        background-color: #fff;
        border: none;
        font-size: 18px;
        width: 60px;
        text-align: center;
        transition: all 100ms ease-in ;
        outline: none;
        margin-inline: 5px;
        /* padding-block: 6px; */
        /* border:solid 1px #D1D5DB; */
        border-radius: 3px;
    }
    #itemQuantity:read-only {
        background-color: #fff;
        font-size: 20px;
        min-width: 25px;
        text-align: center;
        transition: all 100ms ease-in ;
        outline: none;
    }
   
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .owl-item-details:active #itemQuantity {
        /* background-color: #fff;
        width: 20px;
        text-align: center; */
        /* font-size: 25px; */
        transform: translateY(-2px);
        /* font-size: 25px;
        opacity: 0.7; */
    }
    
    .owl-item-details .total{
        min-width: 80px;
        text-align: center;
    }

    .owl-count{
        font-family: "'BoutrosMBCDinkum Medium'";
        font-weight: bold;
        position: absolute;
        font-size: 1rem;
        padding: 2px;
        min-width: 40px;
        left: 0;
        bottom: 0;
        border-top-right-radius:0.7rem;
        border-bottom-left-radius:0.7rem;
        transition: all 0.1s ease-in-out;
    }
    .owl-count-holder {transition: all 0.1s ease-in-out;gap: 0;}
    /* .owl-count-holder:active {border-radius: 1.2rem; margin: -2px; word-spacing: 2px;gap: 4px;} */
    /* .owl-count-holder:active {border-radius: 1rem; margin: -1px; word-spacing: 1px;gap: 2px;} */
    /* .owl-count-holder:active .owl-count{font-size: 1.1rem;padding: 4px; border-top-right-radius: 1.4rem;border-bottom-left-radius:1.4rem;} */

    /* .owl-active{background-color: #22d3ee !important;color: #fff !important; box-shadow: 0px 0px 4px rgba(32, 211, 238,0.2) !important;} */
    .owl-active{
        box-shadow: 0px 0px 6px rgba(32, 211, 238,0.5)!important;
        color:rgb(21 94 117)!important;
    }
    .owl-un-active{
        box-shadow: 0px 0px 6px rgba(0,0,0,0.1)!important;
        color:#2c3e50!important;
        opacity: 0.5;
    }

    .owl-selected[aria-selected="true"]{
        background-color: #22d3ee !important;
        color: #fff !important;
        box-shadow: 0px 0px 4px rgba(32, 211, 238,0.2) !important;
    }
    
    .owl-focus{box-shadow: 0px 0px 4px rgba(32, 211, 238,0.6) !important;}
    .owl-warn{box-shadow: 0px 0px 4px rgba(253 ,224 ,71 ,0.6) !important;}
    .owl-error{box-shadow: 0px 0px 4px rgba(248 ,113 ,113 ,0.6) !important;}
    .owl-ok{box-shadow: 0px 0px 4px rgba(190 ,242 ,100 ,0.6) !important;}
    .owl-focus .state-target{
        /* background-color: rgba(32, 211, 238,0.4); */
        box-shadow: 0px 0px 6px rgba(32, 211, 238,0.5);
        color:rgb(21 94 117)
    }
        
    .owl-warn .state-target{
        /* background-color: rgba(253 ,224 ,71 ,0.4); */
        box-shadow: 0px 0px 6px rgba(253 ,224 ,71 ,0.5);
        color:rgba(253 ,224 ,71 ,0.7)
    }
        
    .owl-error .state-target{
        /* background-color: rgba(248 ,113 ,113 ,0.4); */
        box-shadow: 0px 0px 6px rgba(248 ,113 ,113 ,0.5);
        color:rgb(248 113 113)
    }
        
    .owl-ok .state-target{
        /* background-color: rgba(190 ,242 ,100 ,0.4); */
        box-shadow: 0px 0px 6px rgba(190 ,242 ,100 ,0.5);
        color:rgba(190 ,242 ,100 ,0.7)
    }
    .owl-none .state-target{height: 0;font-size: 0; }



    .owl-expand{
        max-height: 1800px;
        transition: all 0.3s ease-in-out;
        overflow: hidden;
    }
    
    input::placeholder {
        color: rgb(156 163 175);
        letter-spacing: 1px;
        font-size: 14px;
        z-index: 2;
        font-weight: 300;
        position: relative;
        background-color: #fff;
    }
    
    .owl-query-conainer {
        display: grid;
        overflow: hidden;
        padding: 3px;
        gap: 10px;
        border-radius: 0.5rem;
        background-color: #fafafa;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    }
    
    .owl-query-section {
        padding: 8px 6px;
        flex-wrap: wrap;
        display: flex;
        gap: 12px;    
        align-items: center;
        border-radius: 0.5rem;
        /* border:  1px solid #ccc; */
        box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.1);
        overflow: auto;
        background-color: #fff;
    }
    
    .owl-query-cleaner{
        flex-wrap:  wrap;
        display:  flex;
        gap: 10px;
        box-shadow: inset 0px 0px 8px #f8717155;
        border-radius: 0.5rem;
        margin-top: 10px;
        padding: 10px;
    }
    
    .owl-label-conatiner{
        flex-direction: row;
        align-items: center;
        max-width: 160px;
        display:  flex ;
        flex-grow: 1;
        gap: 5px;
        padding: 9px;
        border-radius: 0.5rem;
        background-color: #ffffff;
        justify-content: space-between;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    }
    
    .owl-label{
        color: rgb(156 163 175);
        letter-spacing: 1px;
        font-size: 14px;
        font-weight: 300;
        margin-block: -20px;
        font-family: "'BoutrosMBCDinkum Medium'";
    }
    
    .owl-button{
        font-family: "'BoutrosMBCDinkum Medium'";
        background-color: #ffffff;
        color: rgb(248 113 113);
        border-radius: 0.5rem;
        padding: 4px 16px;
        font-size: 12px;
        border: none;
        cursor: pointer;
        font-weight: bold;
        letter-spacing: 1px;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    }
    
    .owl-button-active{
        background-color: rgb(248 113 113);
        color: #ffffff;
    }
    
    
    .owl-button-conatiner{
        position: relative;
    }

    
    .owl-close-space{
        position: absolute;
        margin-top: 10px;
        margin: -50px;
        padding: 50px;
        z-index: 999;
    }
    .hide {
    display: none;
    }
    
    .clear-icon{
        color: rgb(252, 165, 165);
        font-size: 22px;
        font-family: "'BoutrosMBCDinkum Medium'";
        font-weight: 300;
        left: 10px;
        z-index: 10;
        position: absolute;
        cursor: pointer;
    }
    
    .owl-input {
        font-family: "'BoutrosMBCDinkum Medium'";
        padding: 6px;
        outline: none;
        border: none;
        color: #2d303e;
        font-weight: bold;
        border-radius: 0.5rem;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
    }
    
    .owl-input:focus{box-shadow: 0px 0px 6px rgba(32, 211, 238,0.5);}
    
    .owl-date{
        outline: none;
        border: none;
        font-family: "'BoutrosMBCDinkum Medium'";
        font-weight: bold;
        color: #2d303e;
    }
    
    .owl-options-buttons-container {
        /* color: #fff; */
        background-color: #f9f9f9;
        flex-direction: row;
        display: flex;
        gap: 10px;
        padding:3px;
        border-radius: 0.5rem;
        box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.16);
        overflow-x: auto;
        max-width: 100%;
        transition: all 200ms ease-out
    }
    
    .owl-options-buttons-container::-webkit-scrollbar { height: 4px;width: 4px;}
    .owl-options-buttons-container::-webkit-scrollbar-track {background-color: #e4e4e4;border-radius: 100px;margin: 10px;}
    .owl-options-buttons-container::-webkit-scrollbar-track {background-color: #e4e4e4;}
    .owl-options-buttons-container::-webkit-scrollbar-thumb {background-color: #3f3c4d44;  border-radius: 6px;}
    /* .owl-options-buttons-container:hover::-webkit-scrollbar-thumb {background-color: #3f3c4d55;} */

    /*
    .owl-options-buttons-container:hover::-webkit-scrollbar-track {background-color: #e4e4e4;border-radius: 100px;margin: 10px;}
    .owl-options-buttons-container:hover::-webkit-scrollbar-thumb {border-radius: 6px;background-color: #3f3c4daa;box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);}
    .owl-options-buttons-container:hover::-webkit-scrollbar-track {background-color: #e4e4e4;} */
    
    .owl-button-options{
        /* color: rgb(6 182 212); */
        color: var(--text);
        background-color: #fff;
        border-radius: 0.5rem;
        padding: 10px 10px;
        margin: 2px;
        font-size: 13px;
        border: none;
        cursor: pointer;
        font-weight: bold;
        letter-spacing: 1px;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
        min-width: max-content;
        font-family: "'BoutrosMBCDinkum Medium'";
        font-weight: bold;
    }
    .owl-button-options#active{
        box-shadow: 0px 0px 5px rgba(32, 211, 238,1);
        /* background-color: rgba(6 ,182 ,212, 0.5); */
        color:rgb(21 94 117);
        font-size: 13px;
        padding: 0px 16px;
        /* color: #ffffff; */
    }

    .owl-options {
        /* color: #fff; */
        /* background-color: #2d303e; */
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
        border-radius: 0.5rem;
        padding: 6px 2px;
        outline: none;
        border: none;
        font-family: "'BoutrosMBCDinkum Medium'";
        font-weight: bold;
    }
    
    .owl-toggle-button{
        display: flex;
        margin-inline: 8px;
        align-items: center;
        flex-direction: row;
        margin-block:auto;
    }
    
    .owl-toggleed-bg{
        cursor: pointer;
        padding: 2px;
        display: flex;
        margin: 0 4px;
        border-radius: 50px;
        transition: all 0.2s ease-in-out;
        background-color: #fff;
        box-shadow: 0px 0px 6px rgba(95, 20, 20, 0.16);
        padding-left: 22px;
    }
    .owl-toggleed-bg span{
        padding: 10px;
        border-radius: 24px;
        background-color: #ffffff;
        box-shadow: 0px 0px 6px rgba(95, 20, 20, 0.16);
    }
 
    .text .owl-toggleed-bg span{
        padding: 6px;
        color: #F87171;
    }

    .owl-toggleed {
        padding-right: 22px;
        padding-left: 2px;
        background-color: #F87171;
    }
    .text .owl-toggleed {
        padding-right: 22px;
        padding-left: 2px;
        background-color: unset;
    }
    .text .owl-toggleed span {
        color: #2c3e50;
    }
    
    .owl-radio-container{
        display: flex;
        margin-inline: 10px;
        box-shadow:  0px 0px 3px #f8717155;
        border-radius: 1rem;
        background-color: #fefefe;
        padding: 3px;
        
    }
    .owl-radio-container span {
        transition: all 0.1s ease-out;
        padding: 4px 14px;
        color: #2c3e5099;
        border:solid 1px #0000;
        min-width:80px;
        text-align: center;
        letter-spacing: 1px;
        border-radius: 0.8rem;
        font-weight: 600;
    }
    
    .owl-radio-container .active {
        color: #fff;
        background-color: #f87171;
        /* border:solid 1px #3498dbaa; */
    }

    .radio-label{
        width: 50%;
        max-width: 220px;
    }

    .owl-radio-button{
        display: flex;
        margin-inline: 8px;
        align-items: center;
        flex-direction: row;
        margin-block:auto;
    }
    .owl-radio-dot{
        border-radius: 10px;
        font-size: 0;
        background-color: #ccc;
        border: #fff 5px solid;

        padding : 10px 0 2px 12px; 
        margin-inline: 4px;
    }
   
    /* Consts */
    
  
    
    /* .cut-words {
        max-height: 105px;
    } */
    /* Theme */
    .p-1 {padding:4px;}
    .p-2 {padding:8px;}
    .p-3 {padding:12px;}
    .p-4 {padding:16px;}
    .p-5 {padding:20px;}
    .p-6 {padding:24px;}
    .p-7 {padding:28px;}
    .p-8 {padding:32px;}
    .p-9 {padding:36px;}
    .p-10 {padding:40px;}
    .p-11 {padding:44px;}
    .p-12 {padding:48px;}
    .p-13 {padding:52px;}
    .p-14 {padding:56px;}
    .p-15 {padding:60px;}   

    .px-1 {padding-inline:4px;}
    .px-2 {padding-inline:8px;}
    .px-3 {padding-inline:12px;}
    .px-4 {padding-inline:16px;}
    .px-5 {padding-inline:20px;}
    .px-6 {padding-inline:24px;}
    .px-7 {padding-inline:28px;}
    .px-8 {padding-inline:32px;}
    .px-9 {padding-inline:36px;}
    .px-10 {padding-inline:40px;}
    .px-11 {padding-inline:44px;}
    .px-12 {padding-inline:48px;}
    .px-13 {padding-inline:52px;}
    .px-14 {padding-inline:56px;}
    .px-15 {padding-inline:60px;}
    .px-auto {padding-inline:auto;} 

    .m-1 {margin:4px;}
    .m-2 {margin:8px;}
    .m-3 {margin:12px;}
    .m-4 {margin:16px;}
    .m-5 {margin:20px;}
    .m-6 {margin:24px;}
    .m-7 {margin:28px;}
    .m-8 {margin:32px;}
    .m-9 {margin:36px;}
    .m-10 {margin:40px;}
    .m-11 {margin:44px;}
    .m-12 {margin:48px;}
    .m-13 {margin:52px;}
    .m-14 {margin:56px;}
    .m-15 {margin:60px;}    
    .m-auto {margin:auto;}  

    .mx-1 {margin-inline:4px;}
    .mx-2 {margin-inline:8px;}
    .mx-3 {margin-inline:12px;}
    .mx-4 {margin-inline:16px;}
    .mx-5 {margin-inline:20px;}
    .mx-6 {margin-inline:24px;}
    .mx-7 {margin-inline:28px;}
    .mx-8 {margin-inline:32px;}
    .mx-9 {margin-inline:36px;}
    .mx-10 {margin-inline:40px;}
    .mx-11 {margin-inline:44px;}
    .mx-12 {margin-inline:48px;}
    .mx-13 {margin-inline:52px;}
    .mx-14 {margin-inline:56px;}
    .mx-15 {margin-inline:60px;}
    .mx-auto {margin-inline:auto;}  

    .gap-1 {gap:4px;}
    .gap-2 {gap:8px;}
    .gap-3 {gap:12px;}
    .gap-4 {gap:16px;}
    .gap-5 {gap:20px;}
    .gap-6 {gap:24px;}
    .gap-7 {gap:28px;}
    .gap-8 {gap:32px;}
    .gap-9 {gap:36px;}
    .gap-10 {gap:40px;}
    .gap-11 {gap:44px;}
    .gap-12 {gap:48px;}
    .gap-13 {gap:52px;}
    .gap-14 {gap:56px;}
    .gap-15 {gap:60px;}

    .text-cyan-50   {color: rgb(236 254 255);}
    .text-cyan-100  {color: rgb(207 250 254);}
    .text-cyan-200  {color: rgb(165 243 252);}
    .text-cyan-300  {color: rgb(103 232 249);}
    .text-cyan-400  {color: #22d3ee;}
    .text-cyan-500  {color: rgb(6 182 212);}
    .text-cyan-600  {color: rgb(8 145 178);}
    .text-cyan-700  {color: rgb(14 116 144);}
    .text-cyan-800  {color: rgb(21 94 117);}
    .text-cyan-900  {color: rgb(22 78 99);}
    .text-red-50    {color: rgb(254 242 242);}
    .text-red-100   {color: rgb(254 226 226);}
    .text-red-200   {color: rgb(254 202 202);}
    .text-red-300   {color: rgb(252 165 165);}
    .text-red-400   {color: rgb(248 113 113);}
    .text-red-500   {color: rgb(239 68 68);}
    .text-red-600   {color: rgb(220 38 38);}
    .text-red-700   {color: rgb(185 28 28);}
    .text-red-800   {color: rgb(153 27 27);}
    .text-red-900   {color: rgb(127 29 29);}
    .text-gray-50   {color: rgb(249 250 251);}
    .text-gray-100  {color: rgb(243 244 246);}
    .text-gray-200  {color: rgb(229 231 235);}
    .text-gray-300  {color: rgb(209 213 219);}
    .text-gray-400  {color: rgb(156 163 175);}
    .text-gray-500  {color: rgb(107 114 128);}
    .text-gray-600  {color: rgb(75 85 99);}
    .text-gray-700  {color: rgb(55 65 81);}
    .text-gray-800  {color: rgb(31 41 55);}
    .text-gray-900  {color: rgb(17 24 39);}
    .text-black     {color: rgb(0 0 0);}
    .text-white     {color: rgb(255 255 255);}
    .text-purple-50     {color: rgb(250 245 255);}
    .text-purple-100    {color: rgb(243 232 255);}
    .text-purple-200    {color: rgb(233 213 255);}
    .text-purple-300    {color: rgb(216 180 254);}
    .text-purple-400    {color: rgb(192 132 252);}
    .text-purple-500    {color: rgb(168 85 247);}
    .text-purple-600    {color: rgb(147 51 234);}
    .text-purple-700    {color: rgb(126 34 206);}
    .text-purple-800    {color: rgb(107 33 168);}
    .text-purple-900    {color: rgb(88 28 135);}    

    .text-black     {background-color :rgb(0 0 0);}
    .text-white     {background-color :rgb(255 255 255);}   

    .bg-cyan-50   {background-color: rgb(236 254 255);}
    .bg-cyan-100  {background-color: rgb(207 250 254);}
    .bg-cyan-200  {background-color: rgb(165 243 252);}
    .bg-cyan-300  {background-color: rgb(103 232 249);}
    .bg-cyan-400  {background-color: #22d3ee;}
    .bg-cyan-500  {background-color: rgb(6 182 212);}
    .bg-cyan-600  {background-color: rgb(8 145 178);}
    .bg-cyan-700  {background-color: rgb(14 116 144);}
    .bg-cyan-800  {background-color: rgb(21 94 117);}
    .bg-cyan-900  {background-color: rgb(22 78 99);}    

    .bg-red-50    {background-color: rgb(254 242 242);}
    .bg-red-100   {background-color: rgb(254 226 226);}
    .bg-red-200   {background-color: rgb(254 202 202);}
    .bg-red-300   {background-color: rgb(252 165 165);}
    .bg-red-400   {background-color: rgb(248 113 113);}
    .bg-red-500   {background-color: rgb(239 68 68);}
    .bg-red-600   {background-color: rgb(220 38 38);}
    .bg-red-700   {background-color: rgb(185 28 28);}
    .bg-red-800   {background-color: rgb(153 27 27);}
    .bg-red-900   {background-color: rgb(127 29 29);}   

    .bg-gray-50   {background-color: rgb(249 250 251);}
    .bg-gray-100  {background-color: rgb(243 244 246);}
    .bg-gray-200  {background-color: rgb(229 231 235);}
    .bg-gray-300  {background-color: rgb(209 213 219);}
    .bg-gray-400  {background-color: rgb(156 163 175);}
    .bg-gray-500  {background-color: rgb(107 114 128);}
    .bg-gray-600  {background-color: rgb(75 85 99);}
    .bg-gray-700  {background-color: rgb(55 65 81);}
    .bg-gray-800  {background-color: rgb(31 41 55);}
    .bg-gray-900  {background-color: rgb(17 24 39);}    

    .bg-black     {background-color :rgb(0 0 0);}
    .bg-white     {background-color :rgb(255 255 255);} 

    .rounded-none	{border-radius: 0px;}
    .rounded-sm	{border-radius: 0.125rem; }
    .rounded	{border-radius: 0.25rem; }
    .rounded-md	{border-radius: 0.375rem; }
    .rounded-lg	{border-radius: 0.5rem; }
    .rounded-xl	{border-radius: 0.75rem; }
    .rounded-2xl	{border-radius: 1rem; }
    .rounded-3xl	{border-radius: 1.5rem; }
    .rounded-full	{border-radius: 9999px;}    

    .bg-purple-50     {background-color: rgb(250 245 255);}
    .bg-purple-100    {background-color: rgb(243 232 255);}
    .bg-purple-200    {background-color: rgb(233 213 255);}
    .bg-purple-300    {background-color: rgb(216 180 254);}
    .bg-purple-400    {background-color: rgb(192 132 252);}
    .bg-purple-500    {background-color: rgb(168 85 247);}
    .bg-purple-600    {background-color: rgb(147 51 234);}
    .bg-purple-700    {background-color: rgb(126 34 206);}
    .bg-purple-800    {background-color: rgb(107 33 168);}
    .bg-purple-900    {background-color: rgb(88 28 135);}   