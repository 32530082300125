.owl-print-container{
    font-size: 10px;
    background-color: #fff;
    font-family: "'Cairo ExtraBold'";
    /* font-family: "'Cairo Black'"; */
    overflow: hidden;
}
.owl-print-table {
    /* width: 99vw; */
    user-select: none;
    background-color:var(--table) ;
    color: #000;
    display: grid;
    text-align: center;
    border-right: #000 1px solid;
    max-width: 210mm;
    margin: auto;
}

/* .print-table-header{
    position: sticky;
    position: -webkit-sticky;
    grid-column: 1 / -1;
    top: -12px;
    font-weight: bold;
    background-color: #ececec;
    font-family: "'BoutrosMBCDinkum Medium'";
    font-size: 15px;
    font-weight: 300;
    display: grid;
    border: #000 1px solid;
    grid-template-columns: inherit;
} */

.owl-print-table-td{
    /* border-bottom:#15474e 1px solid; */
    /* color: #2c3e50; */
    /* direction: ltr; */
    text-align: auto;
    padding:4px;
    direction: ltr;
    overflow: hidden;
    border-bottom: solid 1px #000;
    border-left: solid 1px #000;
}
.owl-print-table-header-td{
    /* border-bottom:#15474e 1px solid; */
    /* color: #2c3e50; */
    /* direction: ltr; */
    text-align: auto;
    padding:4px;
    margin-right: -1px;
    direction: ltr;
    border: solid 1px #000;
    border-right: unset;
    /* border-left: solid 1px rebeccapurple ; */
}


.print-table-line{
    padding: 2px;
    top: 0;
    bottom: -2000px;
    background-color:unset;
    position: absolute;
    z-index: 1;
    margin-left: -4px;
}

.print-logo-container{
    border-bottom: 1px solid;
    resize: vertical;
    overflow: auto;
    padding: 10x;
}
.print-logo{
    object-fit:contain;
    /* max-height: 256px;  */
    max-width: 210mm;
    max-height: 220px;
    width: 98%;
    height: 90%;
    margin: auto;
}

@media print {
    .owl-print-container{
        position: absolute;
        inset: 0;
        right: -10px;
        z-index: 99999999;
        overflow: unset;
    }
    .owl-print-table-header-td{overflow: hidden;}
    .owl-print-table {
        width: 99vw;
    }

    /* .owl-print-table-header-td{
        border-left: solid 1px #000 ;
    } */
    .print-table-line{
        padding: 0px;
        top: 0;
        bottom: unset;
        background-color:unset;
        position: absolute;
        z-index: -1;
    }
}


.print-bill-header{
    display: grid;
    padding: 10px 15px;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 10px;
    text-align: center;
}