.owl-popup-keybord{
    display: grid;
    grid-template-columns: repeat(auto-fill , minmax(45px, 1fr));
    position: fixed;
    width: 700px;
    background-color: #ececec;
    padding: 10px 15px;
    border-radius: 0.2rem;
    gap: 0.4rem;
    /* box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.4); */
    border: double 4px #ddd;
    z-index: 9999999;
}

.owl-popup-keybord :nth-child(14){
    grid-column: span 2;
}

.owl-popup-keybord-number{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    position: fixed;
    background-color: #fff;
    padding: 15px 20px;
    max-width: 200px;
    /* box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1); */
    border-radius: 0.2rem;
    gap: 0.7rem;
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.4);
    border: double 4px #ddd;
    z-index: 9999999;
}
.owl-popup-keybord-number :nth-child(13){
    grid-column: span 2;
}

@keyframes bluer {
    0% {
        background-color:#2c3e5000;
        backdrop-filter: blur(0px);
    }
    100% {
        background-color:#2c3e5022;
        backdrop-filter: blur(1px);
    }
}
.owl-popup-keys-container{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    position: absolute;
    background-color: #fff;
    padding: 10px 15px;
    max-width: 200px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    border-radius: 0.2rem;
    gap: 0.4rem;
    /* right: 0;
    left: 0;
    margin: auto; */
}

.owl-popup-button {
    font-family: "'BoutrosMBCDinkum Medium'";
    font-size: 16px;
    font-weight: bold;
    color: #2d303e;
    padding: 6px 0;
    border-radius: 0.2rem;
    cursor: pointer;
    text-align: center;
    background-color: #fff;
    /* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2); */
    box-shadow:  0px 1px 3px rgba(0, 0, 0, 0.2);
}
.owl-popup-button:active {
    background-color: #f9f9f9;
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.2);
}


/* popup-container */
.popup-container{
    position: fixed;
    background-color: #ececec;
    padding: 10px 15px;
    border-radius: 0.2rem;
    gap: 0.4rem;
    /* box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.4); */
    border: double 4px #ddd;
    z-index: 999;
    inset: 0;
    display: flex;
    flex-direction: column;
    background-color: #2d303e55;
    animation: fadeIn 0.2s ease-in-out ;
    overflow: auto;
}


.popup-container .popup-container-2{
    animation: sacleIn 0.2s ease-in-out ;
    margin: auto;
}

@keyframes fadeIn {0% {opacity:0;}100% {opacity: 1;}}
@keyframes sacleIn {0% {scale: 0;}75% {scale: 1.1;}100% {scale: 1;}}
